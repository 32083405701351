.auth-container {
  background-color: #23203a;
}
.siginin-section {
  /* background: url("../../assets/image/bitcoinjpg.jpg") no-repeat; */
  min-height: 100vh;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background-size: 100% 100%;
}

.signin-middle-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.signin-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 100px;
  width: 100px;
}
.outer-form-signin {
  background: #0c1529;
  margin: auto;
  /* width: 55%; */
  border-radius: 10px;
  border: 2px solid #36336d;}
.inner-form-signin {
  /* margin-left: 20px;
      margin-right: 20px; */
  padding: 50px;
  line-height: 25px;
}
.auth-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
  margin-top: -2px;
}

#ip2 {
  border-radius: 5px !important;
  
    border: 1px solid #03325b;
    /* padding: 20px; */
    background: #0c1529;
    color: aliceblue;

}
.text-login-heading {
  font-size: 36px;
  line-height: 36px;
  text-align: center;
}
.reges {
  color: #ff9c42;
  text-decoration: underline;
}
.tele-color {
  color: #5d86da;
  text-decoration: underline;
}
/* .auth-foot {
  background: url(../../image/block-shadow.webp) repeat-x left top;
} */
@media only screen and (max-width: 978px) {
  .outer-form-signin {
    width: 100%;
  }
  .signin-middle-container {
    padding-right: 5px;
    padding-left: 5px;
  }
  .level-inc-container {
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .auth-title {
    margin-top: 30px;
  }
  .inner-form-signin {
    padding-top: 0px !important;
    padding: 25px;
    line-height: 25px;
  }
}
