:root {
  --l-font-size-xs: 14px;
  --l-font-size-s: 16px;
  --l-font-size-m: 18px;
  --l-font-size-l: 20px;
  --l-font-size-l-plus: 24px;
  --l-font-size-xl: 36px;
  --l-font-size-xxl: 48px;
  --l-font-size-xxxl: 64px;
  --l-color-silverwhite: #c4c4c4;
}
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Spectral:ital,wght@1,800&display=swap");
.about-container {
  position: relative;
  z-index: 3;
  padding-bottom: 180px;
}
.yt-video-container {
  position: relative;
}

.timer-video {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;
}
.timer-video iframe {
  width: 560px;
  height: 315px;
  border-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}
.yt-video-container:after,
.yt-video-container:before {
  border-radius: 100%;
  content: "";
  width: 250px;
  height: 250px;
  background: rgba(239, 158, 64, 0.4);
  filter: blur(149px);
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 740px;
}

/* ------------------------------------text-area---------------------------------- */

.about-text-container {
  padding-bottom: 150px;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-text-container:after {
  content: "";
  top: -200px;
  left: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  background: linear-gradient(180deg,rgba(1,1,1,0),#010101 48.32%);
}
.about-text-container:before {
  content: "";
  top: 20px;
  left: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  background: linear-gradient(180deg, rgba(1, 1, 1, 0), #010101 48.32%);
  z-index: 2;
  transform: rotate(-180deg);
}
.about-video-background-top {
  position: absolute;
  top: 100px;
  left: 0;
  width: 70%;
  height: 50%;
  background-color: transparent;
}
.about-video-background-bottom {
  transform: rotate(-180deg);
  position: absolute;
  bottom: 100px;
  right: 0;
  width: 70%;
  height: 50%;
}
.about-video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: transparent;
}
.text-top-heading {
  position: relative;
  z-index: 3;
}
.text-top-heading-inner {
  padding: 10px 120px;
}
.section-title {
  font-weight: 700;
  font-size: var(--l-font-size-xxl);
  line-height: 56px;
  text-align: center;
  margin-bottom: 50px;
  font-family: "Lato", sans-serif;
  font-family: "Spectral", serif;
}
.section-desc {
  font-family: "Lato", sans-serif;
  color:var(--l-color-silverwhite);
  font-size: var(--l-font-size-m);
  line-height: 140%;
  margin-bottom: 20px;
}
.box-hero-container {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  max-width: 730px;
  padding: 40px;
  background: #0d323ca6;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.about-grid-image {
  height: 197px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}
.imgAboutGrid {
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.academy-btn-top {
  padding-top: 70px;
}
.academy-btn {
  border: 1px solid #fff;
  border-radius: 10px;
  transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
  background-color: inherit;
  font-size: var(--l-font-size-l);
  line-height: 23px;
  font-weight: 500;
  padding: 15px 25px;
  color: inherit;
}
.academy-btn:hover {
  border: 1px solid #eeb445;
  color: #eeb445;
}
a {
  text-decoration: none;
}
a:hover {
  color: #fff;
}
@media (max-width: 1200px) {
  .text-top-heading-inner {
    padding: 10px 10px;
  }
  .section-title {
    
    font-size: var(--l-font-size-xxl);
   
  }
}
@media (max-width: 768px) {

  .section-title {
    
    font-size: var( --l-font-size-l-plus);
   
  }
  .about-container {
    position: relative;
    z-index: 3;
    padding-bottom: 30px;
  }
  .timer-video {
   padding: 15px;
}
  .section-desc {
    font-family: "Lato", sans-serif;
  
    font-size: var(  --l-font-size-s);
   
  }
}