.img-mission{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .mission-content{
      text-align: justify;
  }
  /* .our_mission_area {
      margin-top: 50px;
      background-color: #f6faff;
  } */
  .mission-inner-card{
    background: #f7da6a4a;
      padding: 16px;
      border-radius: 10px;
    }
  