.main-dash-container {
  min-height: 100vh;
  background: url(../../image/hero.png)  center top;
  background-size: auto;
  background-color: #0e1422;
}
/* .auth-main-section{
 
  background: url(../../image/loginbox-bg.jpg) no-repeat center top;
} */
.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}
.dash-heading{
  font-family: "Spectral", serif;
}

ul{
  padding-left: 0px !important;
}
.input-group-text {
  background-color: #15223e !important;
  border: 1px solid #22597e !important;
}
a{
  text-decoration: none !important;
}
.dash-card-body {
  background: #23203a !important;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
  color: #fff;
}
.custom-accordion {
  background: #23203a;
}
.rounded-circle-place{
  background-color: #03ff35;
border-radius: 50%;
height: 25px;
width: 25px;
padding: 0px;
}
.rounded-circle-place2{
  background-color: #03a7ff;
border-radius: 50%;
height: 25px;
width: 25px;
padding: 0px;
}
.accordion-button:not(.collapsed) {
  color: #fbfdff;
  background-color: #23203a;
  box-shadow: none;
}
:focus-visible {
  outline: none !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
hr.new3 {
  border-top: 1px dotted red;
}

.hrLeft {
  border: 1px dotted rgb(255, 255, 255);
  width: 37px;
  position: absolute;
  top: -29px;
  right: -27px;
  transform: rotate(318deg);
}
.hrcenter {
  border: 1px dotted rgb(255, 255, 255);
  width: 24px;
  position: absolute;
  top: -29px;
  right: -4px;
  transform: rotate(88deg);
}
.hrright {
  border: 1px dotted rgb(255, 255, 255);
  width: 29px;
  position: absolute;
  top: -29px;
  right: 10px;
  transform: rotate(58deg);
}
.x4hrtop {
  top: -21px;
  width: 32px;
}
/* -------custom button  ------------------ */

.custom-btn {
  /* width: 8.125rem; */
  /* height: 2.5rem; */
  color: #fff;
  border-radius: 5px;
  padding: 0.625em 1.5em;
  font-family: inherit;
  font-weight: 700;
  background: transparent;
  /* cursor: pointer; */
  transition: all 3000ms ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
}
.btn-5:hover {
  color: #529507;
  background: transparent;
  box-shadow: none;
}

.btn-5:before,
.btn-5:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #529507;
  box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003,
    4px 4px 5px 0px #0002;
  transition: 400ms ease all;
}

.btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.btn-5:hover:before,
.btn-5:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

/* -------copy-clipboard-box------------ */

.copy-text {
  position: relative;

  background: #61606000;
  border: 1px solid #656375;
  border-radius: 10px;
  display: flex;
}
.copy-text input.text {
  padding: 10px;
  font-size: 18px;
  background: #0e0d0d00;
  color: #ededed;
  border: none;
  outline: none;
}
.copy-text button {
  padding: 10px;
  background: #5784f5;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.copy-text button:active {
  background: #809ce2;
}
.copy-text button:before {
  content: "Copied";
  position: absolute;
  top: -45px;
  right: 0px;
  background: #5c81dc;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 15px;
  display: none;
}
.copy-text button:after {
  content: "";
  position: absolute;
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: #5c81dc;
  transform: rotate(45deg);
  display: none;
}
.copy-text.active button:before,
.copy-text.active button:after {
  display: block;
}

/* --------tree-data----------------- */
.tree-inner-container {
  border: 1px solid #00b0c2;
  border-radius: 10px;
}
.tree-header {
  background-color: #00b0c2;
  border-top-right-radius: 10px;
  border-top-left-radius: 8px;
  text-align: center;
  color: white;
  font-weight: 500;
}
.tree-gold-inner-conatiner {
  border-radius: 10px;
  border: 1px solid #ffa243;
}
.tree-gold-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 8px;
  background-color: #ffa243;
}
.x4hr2nd {
  width: 27px;
  top: -22px;
  right: -21px;
  transform: rotate(305deg);
}
.x4hr2ndright {
  
    border: 1px dotted rgb(255, 255, 255);
    width: 21px;
    position: absolute;
    top: -26px;
    right: 4px;
    transform: rotate(67deg);

}
.right-child {
  position: relative;
  top: -32px;
  left: -26px;
}
.card-body .form-control:disabled,
.form-control[readonly] {
  background-color: #15223e;
  opacity: 1;
  border: 1px solid #15548b;
}
.card-body .input-group-text {
  color: #ffffff;

  background-color: #6149e1;
  border: 1px solid #a999ff;
}
.card-body .form-control {
  color: #fff;
}

.accordion-button::after {
  background-image: url(../../image/arrowdownpng.png);
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../../image/arrorup.png);
  transform: rotate(0deg);
}

/* -------sidebar  ------ */

.header {
  background: #0c1529c7;;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  border-bottom: 1px solid  #03325b;
}

.toggle {
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: 0;
}

.toggle span {
  width: 100%;
  height: 3px;
  background: #eea630;
  display: block;
  position: relative;
  cursor: pointer;
}
.body-buy-section{
  background: #284239;
border-bottom-left-radius: 10px;
cursor: pointer;
border-bottom-right-radius: 10px;
}

.toggle span:before,
.toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eea630;
  transition: all 0.3s ease-out;
}

.toggle span:before {
  top: -8px;
}

.toggle span:after {
  top: 8px;
}

.toggle span.toggle {
  background: transparent;
}

.toggle span.toggle:before {
  top: 0;
  transform: rotate(-45deg);
  background: #eea630;
}

.toggle span.toggle:after {
  top: 0;
  transform: rotate(45deg);
  background: #eea630;
}

.sidebar {
  background: #0c1529;
  width: 235px;
  position: fixed;
  top: 0;
  left: -235px;
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding-top: 90px;
  transition: all 0.3s ease-out;
  z-index: 1;
}

.sidebar ul {
  list-style: none;
}

.sidebar ul li {
  display: block;
}

.sidebar ul li a {
  padding: 8px 15px;
  font-size: 16px;
  color: #ffffff;
  font-family: arial;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  font-weight: 500;
}

.sidebar ul li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 1px;
  background: #7792ff;
  z-index: -1;
  transition: all 0.3s ease-out;
}

.sidebar ul li a:hover:before {
  width: 100%;
}

.sidebar ul li a:hover {
  color: #9eb2ff;
}

.sidebarshow {
  left: 0;
}

.custom_header .btn_div {
	text-align: right;
}
@media (max-width: 480px) {
.custom_header .btn_div {
	text-align: center!important;
  order: 1;
  margin-bottom: 15px;
}
.logo_div {
  order: 2;
}

}
 


@media (max-width: 450px) {
  .right-child {
    position: relative;

    left: -65px;
  }
  .left-child {
    position: relative;
    left: 37px;
  }
}
@media (max-width: 400px) {
  .right-child {
    position: relative;

    left: -55px;
  }
  .left-child {
    position: relative;
    left: 24px;
  }
}
@media (max-width: 350px) {
  .right-child {
    position: relative;

    left: -42px;
  }
  .left-child {
    position: relative;
    left: 15px;
  }
}
