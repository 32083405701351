.box{
    padding: 20px 10px;
    max-width: 1000px;
    margin: 0 auto;
  }
  .table-data{
    font-size: 16px;
  }
  .table-head{
    font-size: 20px;
  }
  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgb(228 228 228 / 5%);
    color: #ffffffcf;
}
@media (max-width: 768px) {
  .table-head{
    font-size: 13px;
  }
}