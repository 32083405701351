.user-dash-section{
    background: #16162099;
    border-radius: 10px;
}
.card-user{
    background: #00b0c2;
}
.table{
    color: #fff;
}
.hrLeftuser{
    border: 1px dotted rgb(255, 255, 255);
    width: 60px;
    position: absolute;
    top: -26px;
    right: -27px;
    transform: rotate(329deg);
}
.hrcenteruser{
    border: 1px dotted rgb(255, 255, 255);
    width: 24px;
    position: absolute;
    top: -29px;
    right: 33px;
    transform: rotate(88deg);
}
.hrrightuser{
    border: 1px dotted rgb(255, 255, 255);
    width: 62px;
    position: absolute;
    top: -24px;
    right: 64px;
    transform: rotate(33deg);
}
.hrrightuser-2nd{
    border: 1px dotted rgb(255, 255, 255);
    width: 45px;
    position: absolute;
    top: -24px;
    right: 64px;
    transform: rotate(47deg);

}
.user-2ndright-position {
    left: 18px;
}
.user-left-position{
    left: 125px;
}
.user-right-position{
    right: 80px;
}
.user-2ndleft-positionn{
    left: -30px
}
.user-2ndleft-positions{
 left:38px
}
.user-2ndright-positions{
    left:60px 
}
  @media (max-width: 1200px) {
    .user-left-position{
        left: 137px;
    }
    .user-right-position{
        right: 137px;
    }
  }
  @media (max-width: 968px) {
    .user-left-position{
        left: 40px;
    }
    .user-right-position{
        right: 50px;
    }
   
  }
  @media (max-width: 768px) {
    .user-left-position{
        left: 30px;
    }
    .user-right-position{
        right: 40px;
    }
   
  }