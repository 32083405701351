@import url("//db.onlinewebfonts.com/c/150f96b6f2ec67949ea3354a23edd583?family=Dodger+Super-Italic");

:root {
  --l-font-size-l-plus: 24px;
  --l-color-silverwhite: #c4c4c4;
}

.hero-banner {
  padding: 186px 0 186px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: 100% 150%;
}
.hero-video {
  position: absolute;
  right: 0;
  bottom: 100px;
  width: 100%;
  height: 100%;
}
.video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.hero-container {
  position: relative;
  z-index: 2;
}
.hero-suptitle {
  font-family: "Dodger Super-Italic";
  font-style: italic;
  font-weight: 400;
  font-size: 80px;
  line-height: 71px;
  text-transform: uppercase;
}
.hero-title {
  font-family: "Dodger Super-Italic";
  font-style: italic;
  font-size: 132px;
  line-height: 72px;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.hero-desc {
  max-width: 540px;
  margin-bottom: 49px;
  line-height: 28px;
  font-size: var(--l-font-size-l-plus);
}
.hero-banner:after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  background: linear-gradient(180deg, rgba(1, 1, 1, 0), #010101 48.32%);
}
.banner-animate-image {
  height: 550px;
  width: 550px;
  margin-top: -110px;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.hero-connect {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 110px;
  min-width: 305px;
  background: linear-gradient(180deg, #ee6245, #c47a25);
    box-shadow: 0 0 6px #be6600;
  border-radius: 10px;
  --l-font-size-l: 20px;
  --l-font-size-l-plus: 24px;
  line-height: 23px;
  font-weight: 500;
  padding: 15px 25px;
  color: inherit;
}
.hero-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.hero-grid-item {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  background-color: rgb(17 120 117 / 40%);
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%), 0 0 20px rgb(1 1 1 / 28%);
  border-radius: 20px;
}
.hero-grid-left {
  flex-shrink: 0;
  margin-right: 15px;
  width: 54px;
  height: 54px;
}
.large-text {
  font-size: var(--l-font-size-m);
  line-height: 130%;
  color: var(--l-color-silverwhite);
}
.hero-grid-left img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (max-width: 1400px) {
  .banner-animate-image {
    height: 400px;
    width: 400px;
  }
  .hero-suptitle {
    font-size: 70px;
  }
  .hero-title {
    font-size: 112px;
  }
}
@media (max-width: 1200px) {
  .hero-suptitle {
    font-size: 65px;
  }
  .hero-title {
    font-size: 100px;
  }
}
@media (max-width: 991px) {
  .hero-animal {
    width: 400px;
    right: 0;
  }
  .hero-video {
    bottom: 200px;
  }
  .hero-title {
    font-size: 80px;
    line-height: 60px;
  }
  .hero-suptitle {
    font-size: 50px;
  }
  .hero-banner {
    padding: 150px 0;
  }
}
@media (max-width: 767px) {
  .hero-animal {
    top: -25px;
    width: 50%;
    right: calc(50% - 310px);
  }
  .hero-title {
    font-size: 90px;
  }
  .hero-suptitle {
    font-size: 50px;
    line-height: 30px;
  }
  .hero-banner {
    padding: 80px 0;
  }
  .large-text {
    font-size: var(--l-font-size-s);
    line-height: 130%;
  }
  .hero-grid-item {
    margin-bottom: 20px;
  }
  .box-hero-container {
    padding: 20px !important;
  }
  .banner-animate-image {
    height: 285px;
    width: 285px;
  }

  .banner-animate-image {
    height: 330px;
    width: 330px;
  }
  .banner-content-column {
    order: 2;
  
  }
  .banner-image-column {
    order: 1;
    padding-bottom: 30px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .hero-animal {
    top: -12px;
    width: 50%;
    right: -10px;
  }
  .hero-suptitle {
    font-size: 10vw;
    line-height: 30px;
  }
  .hero-title {
    font-size: 14vw;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .hero-banner {
    background-position: center 20%;
    padding: 57px 0 84px;
  }
}
@media (max-width: 476px) {
  .hero-title {
    line-height: 35px;
  }
}
@media screen and (max-width: 400px) {
  .hero-animal {
    top: 0;
    width: 178px;
    right: 0;
  }
  .hero-title {
    font-size: 55px;
    line-height: 38px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 360px) {
  .banner-animate-image {
    height: 280px;
    width: 280px;
  }
}
