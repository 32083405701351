.video-preview-image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    margin-bottom: 20px;
    overflow: hidden;
}
.video-preview__image{
    width: 100%;
    transition: transform .3s;
    cursor: pointer;
    max-width: 100%;
}
.video-preview__image:hover{
    transform: scale(1.1);
}