@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

:root {
  --l-god-gray: #0e0e0e;
  --l-color-black: #010101;
  --l-color-white: #fff;
  --l-color-silver: #c5c5c5;
  --l-color-jafa: #ef9e40;
  --l-color-linear: linear-gradient(180deg, #eeb445, #ef9e40);
  --l-font-size-xs: 14px;
  --l-font-size-s: 16px;
  --l-font-size-m: 18px;
  --l-font-size-l: 20px;
  --l-font-size-l-plus: 24px;
  --l-font-size-xl: 36px;
  --l-font-size-xxl: 48px;
  --l-font-size-xxxl: 64px;
  --l-offset-size-xl: 30px;
  --l-color-yellow: #fedd00;
  --l-color-emerald: #38c948;
  --l-color-silverwhite: #c4c4c4;
  --l-color-everglade: #1d3f21;
  --l-color-cinder: #161620;
  --l-color-charade: #23222c;
  --l-color-silver: #bcbcbc;
  --l-color-tulip: #eeb445;

}
.main-container {
  font-family: "Lato", sans-serif;
  color: var(--l-color-white);
  background-color: #010101;
  font-weight: 300;
  font-size: var(--l-font-size-s);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.nav-list a {
  text-decoration: none;
}
.custom-yellow-text {
  color: var(--l-color-tulip) !important;
}
.nav-header {
  padding-top: 14px;
  position: relative;
  z-index: 10;
}

.btn-heading {
  color: #fff;
  color: var(--l-color-tulip);
  /* border-color: #6c757d; */
  width: 160px;
}
.btn:hover {
  color: #ffffff;
}
.dropdown-menu {
  color: #ffffff !important;

  background-color: #283dff !important;

  border: 1px solid #283dff !important;
}
.dropdown-item {
  color: #ffffff !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff;
  background-color: #555555;
}

.dash-nav-logo {
  height: 55px;
}


.dash-nav-content a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: #303030;;
  border: 1px solid #303030;;
}
.navbar-toggler-icon {
  color: #fff;
  width: 1rem;
  height: 1rem;
}
.dash-nav-content .nav-link:hover {
  color: #ffffff !important;
  font-weight: 600;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.nav-link:hover {
  color: var(--l-color-tulip);
}

@media only screen and (max-width: 978px) {
  .dash-nav-content .nav-item {
    text-align: center;
  }
}
