.earn-container{
    padding-top: 120px;
}
.earn-para-section{
    max-width: 730px;
    margin: 0 auto;
}
@media (max-width: 768px) {
    .earn-container {
        padding-top: 30px;
    }
}